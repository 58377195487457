<template>
  <div data-app>
    <template>
      <v-data-table
          :headers="headers"
          :items="reports"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-card-title class="text-h5 pa-0">
              Danh sách thông báo
              <v-spacer></v-spacer>
            </v-card-title>
          </v-toolbar>
        </template>

        <template v-slot:item.message="{ item }">
          <div class="report-item" v-html="item.message"></div>
        </template>
<!--        <template v-slot:no-results>-->
<!--          <v-alert :value="true" color="error" icon="warning">-->
<!--            Your search for "{{ search }}" found no results.-->
<!--          </v-alert>-->
<!--        </template>-->
      </v-data-table>
    </template>

    <!-- DIALOG DELETE  -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline"
        >Are you sure you want to delete this item?
        </v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete"
          >Cancel
          </v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
          >OK
          </v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
import {validationMixin} from "vuelidate";
import {SET_LOADING} from "@/store/loading.module";

import {GET_REPORT_LIST} from "@/store/report.module";


export default {
  mixins: [validationMixin],
  data: () => ({
    page: 1,
    itemsPerPage: 10,
    headers: [
      {
        text: "ID",
        align: "start",
        sortable: false,
        value: "id",
      },
      {text: " Thông báo ", value: "message"},
      {text: "Ngày tạo", value: "created_at"},
      {text: "Actions", value: "actions", sortable: false},
    ],
    publish_by: "",
    keyword: "",
    category: "",
    startDate: "",
    endDate: "",
    startDialog: "",
    endDialog: "",
    dialog: false,
    dialogDelete: false,
  }),

  computed: {
    ...mapGetters({
      reports: "listReport",
    }),
  },
  mounted() {
    this.getReport();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Báo cáo vi phạm", route: "Báo cáo vi phạm"},
    ]);
  },
  methods: {
    getReport() {
      this.$store.dispatch(SET_LOADING, true);
      this.$store
          .dispatch(GET_REPORT_LIST)
          .then(() => {
            this.$store.dispatch(SET_LOADING, false);
          })
          .catch(() => {
            this.$store.dispatch(SET_LOADING, false);
          });
    },
    addNewItem() {
      this.$router.push({name: "answer-detail"});
    },
  },
};
</script>

<style lang="scss">
 .report-item {
   div,p,h1,h2,h3,h4,h5,h6,a{
     display: inline-block;
   }
 }
</style>
